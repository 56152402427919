<template>
	<b-modal ref="modal" hide-footer>
        <template v-slot:modal-title>
            <template v-if="ajouter">
                {{ $t("invoice.devis.ajouter_document") }}
            </template>

            <template v-else>
                {{ $t("invoice.devis.modifier_document") }}
            </template>
        </template>
        <div>
        	<label>{{ $t('invoice.devis.nom_document') }}* :</label>
	        <div class="form-group">
	            <input type="text" class="form-control" v-model="document_label" :placeholder="$t('invoice.devis.nom_document')" required>
	        </div>

			<label>{{ $t('invoice.devis.fichier') }}* :</label>
	        <b-form-file
	            v-model="document_file"
	            :accept="type_file_accepted"
	            :state="Boolean(document_file)"
	            :placeholder="$t('invoice.devis.choisir_document')"
	            :drop-placeholder="$t('fichier.drop_files')+'...'"
	        ></b-form-file>

	        <div v-show="document_file" class="mt-3">
	            {{ $t('fichier.selected_files') }} :
	            <span> {{ document_file ? document_file.name : '' }}</span>
	        </div>

			<div class="col-12 mt-2 text-center">
				<b-button variant="primary" rounded-pill @click="saveDocument"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /> {{ $t('global.enregistrer') }}</b-button>
			</div>
        </div>
    </b-modal>
</template>

<script type="text/javascript">
    import Quotation from "@/mixins/Quotation.js"

	export default {
		name: 'ModalDevisDocument',
		mixins: [Quotation],
		props: ['quotation_id'],
		data () {
			return {
				document_id: null,
				document_label: '',
				document_file: null,
				type_file_accepted: "image/jpeg, image/jpg, image/png, application/pdf",
				processing: false,
				ajouter: true
			}
		},

		methods: {
			openModal(doc = null) {
                this.$refs.modal.show()
                if(doc) {
                	this.document_id = doc.quotationdocument_id
                	this.document_label = doc.quotationdocument_label
                	this.document_file = new File([], doc.quotationdocument_filename)
                	this.ajouter = false
                }
			},

			closeModal() {
                this.$refs.modal.hide()
				this.document_id = null
				this.document_label = ''
				this.document_file = null
				this.ajouter = true
			},

			async saveDocument() {
				this.processing = true
				if(this.ajouter) {
					const doc = await this.addQuotationDocument(this.quotation_id, this.document_label)
					this.document_id = doc.quotationdocument_id
				}
				else {
					await this.editQuotationDocument(this.quotation_id, this.document_id, this.document_label)
				}
				await this.uploadDocumentQuotationFile(this.quotation_id, this.document_id, this.document_file)
				
				this.processing = false
				this.$emit('submit')
				this.closeModal()
			},
		},

		components: {
			
		}
	}

</script>